import React from "react";
import { graphql, PageProps } from "gatsby";

import Layout from "@/components/Layout";
import Plate from "@/components/Plate";
import Hero from "@/components/Hero";

import { Text, Box, Container } from "theme-ui";

export interface HomeProps {
  data: any;
}

const VisionPage: React.FC<HomeProps> = () => (
  <Layout>
    <Plate variant="styles.contentFrame">
      
        <Plate variant="styles.platePage">
        <Container sx={{ width: '100ch'}}>
          <Text as="h1" variant="pageTitle">
            Our Vision
          </Text>
          <Text as="p" variant="sectionBody">
            Our vision is to provide a patient-centered healthcare system
            delivering economical, high quality and safe surgical care. We aim
            to promote a futuristic approach to healthcare wherein no surgeon or
            surgery is restricted by the lack of operation theater availability.
          </Text>
          </Container>
        </Plate>
      
    </Plate>
  </Layout>
);

export default VisionPage;
